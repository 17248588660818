import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Collapse, ListItem } from '@material-ui/core';
import ChevronDownIcon from '../icons/ChevronDown';
import ChevronRightIcon from '../icons/ChevronRight';
import useSettings from '../hooks/useSettings';
import { useTheme } from '@material-ui/core/styles';

const NavItem = (props) => {
  const { active, children, depth, icon, info, open: openProp, path, title, ...other } = props;
  const [open, setOpen] = useState(openProp);
  const theme = useTheme();

  const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 25;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 0,
          borderBottom: `1px solid ${theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[50]}`
        }}
        {...other}
      >
        <Button
          endIcon={!open ? <ChevronRightIcon fontSize="small" />
            : <ChevronDownIcon fontSize="small" />}
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: 'text.secondary',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: `${paddingLeft}px`,
            py: '12px',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            borderRadius: '0',
            '&:hover, &.active': {
                color: theme.palette.primary.main
            }
            // '& + div': {
            //     background: '#eee'
            // }
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>
            {title}
          </Box>
          {info}
        </Button>
        <Collapse in={open}>
            <Box sx={{
                borderRadius: '0',
                background: theme.palette.grey[100],
                boxShadow: '0 0 30px #d1d1d1 inset',
                overflow: 'hidden',
                '& li': {
                    borderColor: theme.palette.grey[300]
                },
                '& a': {
                    color: theme.palette.mode === 'dark' ? theme.palette.grey['A700']  : theme.palette.text.secondary,
                    lineHeight: 'normal',
                    borderRadius: '0px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&:before': {
                        content: '""'
                    }
                },
                '& a:hover': {
                    background: theme.palette.secondary.main,
                    color: 'white'
                },
                '& li a.active': {
                    color: theme.palette.secondary.main
                },
                '& li a.active:hover': {
                    color: theme.palette.secondary.contrastText
                },
            }}>
                {children}
            </Box>
        </Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
        borderBottom: `1px solid ${theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[50]}`,
        '&:last-of-type': {
            borderBottom: 0
        }
      }}
    >
      <Button
        component={path && RouterLink}
        startIcon={icon}
        sx={{
          color: 'text.secondary',
          fontWeight: 'fontWeightMedium',
          justifyContent: 'flex-start',
          textAlign: 'left',
          pl: `${paddingLeft}px`,
          pr: `${paddingLeft}px`,
          py: '12px',
          textTransform: 'none',
          borderRadius: '0',
          width: '100%',
          '&:hover, &.active': {
              color: theme.palette.primary.main
          },
          ...(active && {
            color: 'primary.main',
            fontWeight: 'fontWeightBold',
            '& svg': {
              color: 'primary.main'
            }
          })
        }}
        variant="text"
        to={path}
      >
        <Box sx={{ flexGrow: 1 }}>
          {title}
        </Box>
        {info}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  active: false,
  open: false
};

export default NavItem;
