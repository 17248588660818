import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Suscription = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 223.816 174.524">
        <path d="M144.435,155.893a74.024,74.024,0,0,1-105.7-66.2c.538.537,5.453,5.453,10.445,10.5,5.3,5.325,14.592-1.075,14.592-1.075L32.026,67.267,5.377,103.44s10.137,4.966,14.592-1.1c3.789-5.145,7.5-10.189,8.55-11.623a84.282,84.282,0,0,0,8.064,34.3h0a84.272,84.272,0,0,0,141.26,17.459l-7.911-6.5a72.773,72.773,0,0,1-25.5,19.917Z" transform="translate(-0.831 -1.726)" style={{strokeMiterlimit: '10', strokeWidth: '6px'}}/>
        <path d="M207.1,87.362c-4.506,4.045-8.909,7.987-10.47,9.421.256-2.585.41-5.2.41-7.834A84.289,84.289,0,0,0,41.756,43.612l8.627,5.5A74.21,74.21,0,0,1,112.719,14.94,74.491,74.491,0,0,1,151.861,26.1a74.169,74.169,0,0,1,34.97,62.822c0,2.407-.154,4.787-.384,7.168l-.154-.025s-4.1-6.221-8.192-12.519c-4.121-6.3-14.515-1.894-14.515-1.894l24.6,37.632,33.459-30.029c0,.025-8.935-6.912-14.541-1.894Z" transform="translate(-0.831 -1.726)" style={{strokeMiterlimit: '10', strokeWidth: '6px'}}/>
        <path d="M143.754,46.534l-3.572,16.771q-5.148-5.145-15.016-5.146a20.106,20.106,0,0,0-15.984,7.084,23.455,23.455,0,0,0-4.9,9.99h33.3l-1.937,9.385H103.067q-.061,1.394-.061,3.572,0,2.121.121,4.42h30.818L132.008,102H104.4a23.813,23.813,0,0,0,4.662,10.172,19.66,19.66,0,0,0,15.742,7.144q11.625,0,18.527-7.144v18.527a40.54,40.54,0,0,1-18.346,3.936q-17.679,0-29.062-12.109A41.1,41.1,0,0,1,85.69,102H76.729l1.938-9.385H84.6q-.06-1.453-.06-3.088,0-2.784.121-4.9H76.729l1.938-9.385h7.2A41.406,41.406,0,0,1,95.983,55.071q11.443-12.23,30.091-12.231A39.635,39.635,0,0,1,143.754,46.534Z" transform="translate(-0.831 -1.726)"/>
    </svg>
, 'Suscription'
);

export default Suscription;
