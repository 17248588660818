import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Thunder = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M17.729,7.088,5.854,19.9a.313.313,0,0,1-.518-.332l3.57-8.63H2.5a.313.313,0,0,1-.276-.46L7.739.165A.311.311,0,0,1,8.014,0h4.8a.312.312,0,0,1,.277.456L9.938,6.563H17.5a.312.312,0,0,1,.229.525Z" />
  </svg>, 'Thunder'
);

export default Thunder;
