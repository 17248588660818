import * as Yup from 'yup';
import { Formik } from 'formik';
import { THEMES } from '../../../constants.js';
import { Alert, Box, Button, FormHelperText, TextField } from '@material-ui/core';
// import useAuth from '../../../hooks/useAuth';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

const LoginJWT = (props) => {
  const isMountedRef = useIsMountedRef();
  const { login } = useAuth();
  //PIXIM LOGIN
  return (
    <Formik
      initialValues={{
        login: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup
        .object()
        .shape({
          login: Yup
            .string()
            .email('Must be a valid email')
            .max(255)
            .required('Champ obligatoire'),
          password: Yup
            .string()
            .max(255)
            .required('Champ obligatoire')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {

                await login(values.login, values.password);

                if (isMountedRef.current) {
                    setStatus({ success: true });
                    setSubmitting(false);

                    if(typeof props.close === 'function')
                        props.close.call();
                }
            } catch (err) {
                if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
        >
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Adresse e-mail"
            margin="normal"
            name="login"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.login}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Mot de passe"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          {errors.submit && (
            <Box>
              <FormHelperText error dangerouslySetInnerHTML={{__html: errors.submit}} />
            </Box>
          )}
          <Box sx={{ mt: 2, mb: 2 }}>
            <Button
              color="secondary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ p: 2 }}
            >
              Connexion
            </Button>
          </Box>

        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
