import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Supplier = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M17.783,11.058a13.433,13.433,0,0,1-1.719-1.216,22.04,22.04,0,0,0-2.652-1.85C12.353,7.5,10.757,6.645,10.2,6.345c-.854.016-1.747.034-2.24.048l-.053.1A4.18,4.18,0,0,1,6.116,8.607a2.9,2.9,0,0,1-1.047.19,1.522,1.522,0,0,1-1.277-.506A1,1,0,0,1,3.608,7.5,11.456,11.456,0,0,1,4.38,4.179c-.428-.2-1.108-.54-1.639-.812a.715.715,0,0,0-.745,0,.859.859,0,0,0-.321.4L.146,8.135A2.134,2.134,0,0,0,.04,8.81a.817.817,0,0,0,.415.621,7.128,7.128,0,0,1,1.276,1.138,3.048,3.048,0,0,1,.425,1.142,1.668,1.668,0,0,1,1.261-.634,1.374,1.374,0,0,1,.314.035,1.645,1.645,0,0,1,1.129.994A1.658,1.658,0,0,1,5.421,12a1.488,1.488,0,0,1,.758.207,1.435,1.435,0,0,1,.642.814,1.6,1.6,0,0,1,.325-.037,1.671,1.671,0,0,1,.859.245,1.561,1.561,0,0,1,.675,1.559,1.408,1.408,0,0,1,.667.193,2.08,2.08,0,0,1,.635,2.193l-.01.045-.015.04a1.194,1.194,0,0,0,.3.214,1.244,1.244,0,0,0,1.889-1.182,1.646,1.646,0,0,0,1.866-.3,1.514,1.514,0,0,0,.232-1.291,1.873,1.873,0,0,0,2.143-.175,1.043,1.043,0,0,0,.121-1.289s1.178.473,1.73-.311C18.76,12.184,18.429,11.606,17.783,11.058Z" />
    <path d="M8.609,17.593a.911.911,0,0,1-.431-.145.824.824,0,0,1-.246-1.192l.078-.145A1.062,1.062,0,0,1,9.246,15.7,1.346,1.346,0,0,1,9.5,16.98l-.032.087C9.189,17.577,8.932,17.617,8.609,17.593Z" />
    <path d="M4.439,7.632S4.73,4.705,5.448,4.068A3.882,3.882,0,0,1,7.706,3.08c.976-.165,3.09-.878,3.82-.666s1.94.542,2.663.684,1.58.677,2.009.706a4.478,4.478,0,0,0,1.321-.5.6.6,0,0,1,.616.064.828.828,0,0,1,.309.444L19.91,8.377a1.312,1.312,0,0,1,.05.451.663.663,0,0,1-.356.51,8.312,8.312,0,0,0-1.329.983c-.121.224-3.247-2.5-4.509-3.091S10.407,5.5,10.407,5.5s-2.688.049-2.9.07S6.721,7.46,5.8,7.827C5.264,8.043,4.333,8,4.439,7.632Z" />
    <path d="M3.252,14.235a.806.806,0,0,1-.138-.02.917.917,0,0,1-.691-1.1l.053-.234c.112-.494.575-1.066,1.069-.953a1.107,1.107,0,0,1,.632,1.3l-.051.328A.8.8,0,0,1,3.252,14.235Z" />
    <path d="M4.783,15.464a.694.694,0,0,1-.358-.078.987.987,0,0,1-.363-1.3l.428-.634c.258-.436.825-.775,1.262-.517s.357.865.162,1.317l-.344.728A.852.852,0,0,1,4.783,15.464Z" />
    <path d="M6.877,16.5a.917.917,0,0,1-.716-1.39l.567-.811a.835.835,0,0,1,1.177-.345c.432.262.287,1.009.1,1.491l-.28.615A.918.918,0,0,1,6.877,16.5Z" />

  </svg>, 'Supplier'
);

export default Supplier;
