import { THEMES } from '../constants.js';

export default async (url, data = '') => {

    url = THEMES.API_URL+'?'+url;

    if(typeof data == 'object' && data.constructor.name !== FormData) {
        let d = Object.assign({}, data); //Cloner l'objet
        data = new Array();
        for(let i in d) {
            if(d[i] !== null)
                data.push(`${i}=${d[i]}`);
        }
        data = data.join('&');
    }

    window.open(data ? url+'&'+data : url, '_blank');
};
