import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Button, Divider, Drawer, Hidden, Link, Typography } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import useAuth from '../../hooks/useAuth';
import { useTheme } from '@material-ui/core/styles';
import BriefcaseIcon from '../../icons/Briefcase';
import CalendarIcon from '../../icons/Calendar';
import ChartPieIcon from '../../icons/ChartPie';
import SettingsIcon from '@material-ui/icons/Settings';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import Cycle from '../../icons/Cycle';
import Klaxon from '../../icons/Klaxon';
import Suscription from '../../icons/Suscription';
import Key from '../../icons/Key';
import Check from '../../icons/Check';
import Thunder from '../../icons/Thunder';
import Maintenance from '../../icons/Maintenance';
import Cog from '../../icons/Cog';
import Supplier from '../../icons/Supplier';
import Home from '../../icons/Home';
import ChatAltIcon from '../../icons/ChatAlt';
import ClipboardListIcon from '../../icons/ClipboardList';
import FolderOpenIcon from '../../icons/FolderOpen';
import MailIcon from '../../icons/Mail';
import ShareIcon from '../../icons/Share';
import ShoppingBagIcon from '../../icons/ShoppingBag';
import ShoppingCartIcon from '../../icons/ShoppingCart';
import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';

const sections = [
  {
    title: '',
    path: '/',
    items: [
      {
        title: 'Accueil',
        path: '/',
        icon: <Home fontSize="small" />
      }
    ]
  },
  {
    title: 'Locations',
    access: ['Administrateur', 'Location', 'Accueil'],
    items: [
      {
        title: 'Locations',
        path: '/locations',
        icon: <Key fontSize="small" />,
        children: [
            {
              title: 'Contrats',
              path: '/contrats'
            },
            {
                title: 'Nouvelle inscription',
                path: '/commandes/nouvelle'
            },
            {
              title: 'État des lieux',
              access: ['Administrateur', 'Location'],
              path: '/etatsdeslieux'
            }
        ]
      },
      {
        title: 'Clients',
        path: '/clients',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: 'Liste',
            path: '/clients'
          },
          {
            title: 'Ajouter un client',
            path: '/clients/nouveau'
          },
          {
            title: 'Historique des commandes',
            path: '/commandes'
          }
        ]
      },
      {
        title: 'Vélos',
        path: '/velos',
        access: ['Administrateur', 'Location'],
        icon: <Cycle fontSize="small" />,
        children: [
            {
                title: 'Liste',
                path: '/velos'
            },
            {
                title: 'Modèles',
                path: '/modeles'
            },
            {
                title: 'Marques',
                path: '/marques'
            }
        ]
      },
      {
        title: 'Accessoires',
        path: '/accessoires',
        access: ['Administrateur', 'Location'],
        icon: <Klaxon fontSize="small" />,
        children: [
            {
                title: 'Liste',
                path: '/accessoires'
            },
            {
                title: 'Nouveau',
                path: '/accessoires/nouveau'
            }
        ]
      },
      {
            title: 'Abonnements',
            path: '/abonnements',
            icon: <Suscription fontSize="small" />,
            access: ['Administrateur', 'Location', 'Accueil']
      },
      {
        title: 'Sinistres',
        path: '/sinistres',
        icon: <Thunder fontSize="small" />
      }
      // {
      //   title: 'Orders',
      //   icon: <FolderOpenIcon fontSize="small" />,
      //   path: '/orders',
      //   children: [
      //     {
      //       title: 'List',
      //       path: '/orders'
      //     },
      //     {
      //       title: 'Details',
      //       path: '/orders/1'
      //     }
      //   ]
      // },
      // {
      //   title: 'Invoices',
      //   path: '/invoices',
      //   icon: <ReceiptIcon fontSize="small" />,
      //   children: [
      //     {
      //       title: 'List',
      //       path: '/invoices'
      //     },
      //     {
      //       title: 'Details',
      //       path: '/invoices/1'
      //     }
      //   ]
      // }
    ]
  },
  {
    title: 'Maintenance',
    access: ['Administrateur', 'Location', 'Atelier'],
    items: [
      {
        title: 'Pièces',
        access: ['Administrateur', 'Maintenance'],
        path: '/pieces',
        icon: <Cog fontSize="small" />,
        children: [
            {
                title: 'Liste',
                path: '/pieces'
            },
            {
                title: 'Nouveau',
                path: '/pieces/nouvelle'
            }
        ]
      },
      {
          title: 'Maintenances',
          access: ['Administrateur', 'Maintenance'],
          icon: <Maintenance fontSize="small" />,
          path: '/maintenances',
          children: [
              {
                  title: 'Liste',
                  path: '/maintenances'
              },
              {
                  title: 'Nouvelle maintenance',
                  path: '/maintenances/nouvelle'
              }
          ]
      },
      {
          title: 'Fournisseurs',
          icon: <Supplier fontSize="small" />,
          path: '/fournisseurs'
      }
    ]
  },
  // {
  //   title: 'Platforms',
  //   items: [
  //     {
  //       title: 'Projects',
  //       path: '/projects',
  //       icon: <BriefcaseIcon fontSize="small" />,
  //       children: [
  //         {
  //           title: 'Browse',
  //           path: '/projects/browse'
  //         },
  //         {
  //           title: 'Details',
  //           path: '/projects/1'
  //         },
  //         {
  //           title: 'Create',
  //           path: '/projects/new'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Social',
  //       path: '/social',
  //       icon: <ShareIcon fontSize="small" />,
  //       children: [
  //         {
  //           title: 'Profile',
  //           path: '/social/profile'
  //         },
  //         {
  //           title: 'Feed',
  //           path: '/social/feed'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   title: 'Agenda',
  //   items: [
  //     // {
  //     //   title: 'Kanban',
  //     //   path: '/kanban',
  //     //   icon: <ClipboardListIcon fontSize="small" />
  //     // },
  //     // {
  //     //   title: 'Mail',
  //     //   path: '/mail',
  //     //   icon: <MailIcon fontSize="small" />
  //     // },
  //     // {
  //     //   title: 'Chat',
  //     //   path: '/chat',
  //     //   icon: <ChatAltIcon fontSize="small" />
  //     // },
  //     {
  //       title: 'Calendrier',
  //       path: '/calendar',
  //       icon: <CalendarIcon fontSize="small" />
  //     }
  //   ]
  //   },
  {
      title: 'Statistiques',
      access: ['Administrateur'],
      items: [
          {
              title: 'Analytics',
              path: '/analytics',
              icon: <ChartPieIcon fontSize="small" />
          }
      ]
  },
  {
      title: 'Paramétrages',
      access: ['Administrateur'],
      items: [
          {
              title: 'Paramétrages',
              path: '/parametrages',
              icon: <SettingsIcon fontSize="small" />
          }
      ]
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const theme = useTheme();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  // height: 40,
                  width: 200
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
              boxShadow: theme.shadows[3]
            }}
          >
            <RouterLink to="/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48,
                  boxShadow: theme.shadows[7]
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/account"
                >
                  {user.plan}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 0 }}>
            {sections.map((section, index) => {

                if(section.access && section.access.length > 0) {
                    if(section.access.indexOf(user.plan) < 0) //Forbidden
                        return '';
                }

                {section.items.map((element, index) => {
                    if(element.access && element.access.length > 0) {
                        if(element.access.indexOf(user.plan) < 0) //Forbidden
                            delete section.items[index]; //Suppression de l'élément
                    }
                })}
                return (
                    <NavSection
                      key={index}
                      pathname={location.pathname}
                      sx={{
                        '& + &': {
                        }
                      }}
                      {...section}
                    />
                );
            })}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
