import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('svg')();

const LogoWhite = (props) => (
  <LogoRoot
    version="1.1"
    viewBox="0 0 44.603 25.097"
    width="250"
    {...props}
  >
  <title>Avaé</title>
  <path d="M12.276,19.968c-.012-.048,4.791-2.071,5.85-.547.828,1.191-1.089,3.877-1.206,3.829s1.381-2.38.548-3.464c-1.063-1.385-5.181.227-5.192.182"
    style={{fill: '#fff'}}
  />
  <path d="M34.211,14.192q.378-.945.755-1.889A6.218,6.218,0,0,1,39.612,9.93a4.589,4.589,0,0,1,3.226,1.205,4.131,4.131,0,0,1,1.2,2.949c0,3.5-3.108,5.411-8.7,5.381v.3c0,1.2.329,3.348,3.288,3.348,1.345,0,1.434,1.943-.388,1.943a6.3,6.3,0,0,1-3.849-1.25,6.045,6.045,0,0,1-1.6-5.992q.71-1.811,1.417-3.62m1.067,3.449c2.87.06,6.128-.449,6.128-3.258a2.328,2.328,0,0,0-2.481-2.511,3.6,3.6,0,0,0-3.5,2.421,2.742,2.742,0,0,0-.15.837Z"
    style={{fill: '#fff'}}
  />
  <path d="M32.2,17.763a5.39,5.39,0,0,1-4.424,2.152,4.122,4.122,0,0,1-4.424-4.155c0-3.5,3.108-5.41,8.7-5.38v-.3a3.494,3.494,0,0,0-.883-2.544,3.448,3.448,0,0,0-2.405-.8,1.1,1.1,0,0,1-1.055-1.7c1.23-.893,3.787-.382,5.292,1a5.971,5.971,0,0,1,1.568,5.983c-.759,1.914-2.367,5.74-2.367,5.74m-.09-5.559c-2.87-.06-6.128.448-6.128,3.257a2.329,2.329,0,0,0,2.481,2.512,3.6,3.6,0,0,0,3.5-2.422,2.737,2.737,0,0,0,.15-.837Z"
    style={{fill: '#fff'}}
  />
  <path d="M39.1,8.106c.113.447,1.778.555,3.064.042,1.619-.647,2.683-2.3,2.392-2.686-.508-.681-5.67,1.8-5.456,2.644"
    style={{fill: '#fff'}}
  />
  <path d="M8.877,17.656a5.387,5.387,0,0,1-4.423,2.152A4.122,4.122,0,0,1,.029,15.653c0-3.5,3.109-5.41,8.7-5.38v-.3A3.494,3.494,0,0,0,7.845,7.43a3.449,3.449,0,0,0-2.4-.8,1.1,1.1,0,0,1-1.055-1.7c1.23-.893,3.786-.382,5.292,1a5.971,5.971,0,0,1,1.568,5.983c-.76,1.914-2.368,5.74-2.368,5.74M8.788,12.1c-2.87-.06-6.128.448-6.128,3.257a2.329,2.329,0,0,0,2.481,2.512,3.6,3.6,0,0,0,3.5-2.422,2.737,2.737,0,0,0,.15-.837Z"
    style={{fill: '#fff'}}
  />
  <path d="M12.532,20.1q-2.721,0-2.72-2.266,0-1.571,2.447-7.253a60.636,60.636,0,0,0,3.234-8.461,15.094,15.094,0,0,0-5.485.922q-2.371.921-2.372,2.614a1.433,1.433,0,0,0,.242.816,1.7,1.7,0,0,0,.484.513q.481.3,1.087.6.363.272.121.332a.889.889,0,0,1-.211-.03,3.514,3.514,0,0,1-1.511-.876A2.275,2.275,0,0,1,7,5.349a1.476,1.476,0,0,1-.121-.544A2.963,2.963,0,0,1,8.225,2.357a6.985,6.985,0,0,1,3.022-1.284A16.747,16.747,0,0,1,13.952.786q1.028,0,1.6.03a1.176,1.176,0,0,0-.181-.453c-.1-.161-.041-.273.181-.333h.091c.2,0,.393.151.574.454l.151.423a10.772,10.772,0,0,1,2.871.755,1.5,1.5,0,0,1,.725.619c.182.293.121.429-.181.409a3.7,3.7,0,0,1-1-.257A8.837,8.837,0,0,0,16.4,2.115a18.52,18.52,0,0,1-1.663,6.5q-2.9,6.769-2.9,9.156,0,1.542,1.24,1.542,1.511,0,4.291-2.116a26.6,26.6,0,0,0,6.346-7.312q.392-.636,1.48-2.418a48.635,48.635,0,0,1,3.506-5.2A6.3,6.3,0,0,1,30.738.574,4.317,4.317,0,0,1,32.506,0a2.238,2.238,0,0,1,.967.166q.333.167.333.3c0,.091-.051.136-.152.136l-.332-.03q-1,0-2.81,2.206-1.149,1.39-3.188,4.14t-2.947,3.929A43.4,43.4,0,0,1,17.7,17.905a8.688,8.688,0,0,1-5.167,2.19"
    style={{fill: '#fff'}}
  />
  <path d="M1.565,24.622a.632.632,0,0,1-.191.277.869.869,0,0,1-.639.176,1.12,1.12,0,0,1-.377-.118.993.993,0,0,1-.3-.253.151.151,0,0,1,.025-.213.153.153,0,0,1,.213.024.717.717,0,0,0,.474.259.567.567,0,0,0,.414-.113.315.315,0,0,0,.106-.307c-.051-.213-.246-.283-.562-.371a2,2,0,0,1-.307-.1c-.022-.01-.043-.019-.064-.031l-.012-.006a.716.716,0,0,1-.2-.167.6.6,0,0,1-.125-.259.566.566,0,0,1,.018-.335.6.6,0,0,1,.192-.276.855.855,0,0,1,.639-.177,1.055,1.055,0,0,1,.374.119,1.035,1.035,0,0,1,.3.252.155.155,0,0,1-.024.214.152.152,0,0,1-.213-.028.727.727,0,0,0-.475-.256.565.565,0,0,0-.417.11.311.311,0,0,0-.1.307c.049.213.243.283.56.372a2.882,2.882,0,0,1,.31.1c.022.012.043.021.061.03l.015.009a.677.677,0,0,1,.2.168.585.585,0,0,1,.124.255.6.6,0,0,1-.015.338"
    style={{fill: '#fff'}}
  />
  <path d="M1.858,25.045a.148.148,0,0,1-.092-.031.151.151,0,0,1-.045-.188l1.013-2.087a.155.155,0,0,1,.137-.086.153.153,0,0,1,.137.086l1.019,2.084a.15.15,0,0,1-.07.2.153.153,0,0,1-.2-.067l-.237-.49a1.069,1.069,0,0,0-.5-.018,1.811,1.811,0,0,0-.52.185,2.732,2.732,0,0,0-.53.368.152.152,0,0,1-.1.043m1.013-1.892-.612,1.262c.028-.015.055-.031.086-.046a2.118,2.118,0,0,1,.608-.219,1.484,1.484,0,0,1,.4-.021Z"
    style={{fill: '#fff'}}
  />
  <path d="M5.278,25.081a1.1,1.1,0,0,1-.569-.137.807.807,0,0,1-.313-.319,1.158,1.158,0,0,1-.125-.551V22.806a.155.155,0,0,1,.152-.153.153.153,0,0,1,.152.153v1.268a.85.85,0,0,0,.089.4.509.509,0,0,0,.2.2.836.836,0,0,0,.417.094.838.838,0,0,0,.417-.094.514.514,0,0,0,.194-.2.866.866,0,0,0,.089-.408V22.806a.155.155,0,0,1,.152-.153.153.153,0,0,1,.152.153v1.268a1.152,1.152,0,0,1-.125.551.91.91,0,0,1-.879.456"
    style={{fill: '#fff'}}
  />
  <path d="M8.726,25.033a.155.155,0,0,1-.153-.153V23.259l-.748,1.025a.144.144,0,0,1-.122.061.153.153,0,0,1-.124-.061L6.83,23.259V24.88a.153.153,0,0,1-.152.153.155.155,0,0,1-.152-.153V22.794A.153.153,0,0,1,6.8,22.7l.9,1.235L8.6,22.7a.152.152,0,0,1,.17-.055.157.157,0,0,1,.1.147V24.88a.153.153,0,0,1-.152.153"
    style={{fill: '#fff'}}
  />
  <path d="M10.129,25.081a1.1,1.1,0,0,1-.569-.137.81.81,0,0,1-.314-.319,1.166,1.166,0,0,1-.124-.551V22.806a.155.155,0,0,1,.152-.153.153.153,0,0,1,.152.153v1.268a.858.858,0,0,0,.088.4.518.518,0,0,0,.2.2.97.97,0,0,0,.833,0,.509.509,0,0,0,.2-.2.875.875,0,0,0,.088-.408V22.806a.155.155,0,0,1,.152-.153.153.153,0,0,1,.153.153v1.268a1.152,1.152,0,0,1-.125.551.911.911,0,0,1-.879.456"
    style={{fill: '#fff'}}
  />
  <path d="M11.529,25.045a.153.153,0,0,1-.153-.153V22.806a.152.152,0,0,1,.153-.153h.9a.72.72,0,0,1,.18.025.741.741,0,0,1,.34.188.675.675,0,0,1,.159.244.934.934,0,0,1,.06.353.7.7,0,0,1-.349.639.889.889,0,0,1-.183.076,1.588,1.588,0,0,1,.344.422c.079.135.164.135.176.135a.153.153,0,0,1,.171.127.155.155,0,0,1-.128.174.427.427,0,0,1-.186-.022.562.562,0,0,1-.295-.261,1.211,1.211,0,0,0-.389-.423.62.62,0,0,0-.192-.091.153.153,0,0,1,.03-.3,1.03,1.03,0,0,0,.5-.1.385.385,0,0,0,.2-.374.591.591,0,0,0-.067-.3.376.376,0,0,0-.14-.14.513.513,0,0,0-.228-.064h-.751v1.934a.153.153,0,0,1-.152.153"
    style={{fill: '#fff'}}
  />
  <path d="M15.728,25.051a.153.153,0,0,1-.143-.094l-.871-2.106a.156.156,0,0,1,.083-.2.153.153,0,0,1,.2.083l.731,1.764.73-1.764a.151.151,0,1,1,.28.115l-.87,2.106a.153.153,0,0,1-.14.094"
    style={{fill: '#fff'}}
  />
  <path d="M16.613,25.045a.145.145,0,0,1-.091-.031.15.15,0,0,1-.046-.188l1.013-2.087a.158.158,0,0,1,.138-.086.152.152,0,0,1,.136.086l1.019,2.084a.149.149,0,0,1-.069.2.152.152,0,0,1-.2-.067l-.238-.49a1.069,1.069,0,0,0-.505-.018,1.822,1.822,0,0,0-.52.185,2.7,2.7,0,0,0-.529.368.152.152,0,0,1-.1.043m1.014-1.892-.612,1.262c.027-.015.055-.031.085-.046a2.134,2.134,0,0,1,.609-.219,1.476,1.476,0,0,1,.395-.021Z"
    style={{fill: '#fff'}}
  />
  <path d="M20.444,25.045H19.118a.153.153,0,0,1-.152-.153V22.806a.153.153,0,0,1,.152-.153.152.152,0,0,1,.152.153v1.935h1.174a.152.152,0,1,1,0,.3"
    style={{fill: '#fff'}}
  />
  <path d="M25.7,25.045H24.37a.153.153,0,0,1-.152-.153V22.806a.153.153,0,0,1,.152-.153.152.152,0,0,1,.152.153v1.935H25.7a.152.152,0,1,1,0,.3"
    style={{fill: '#fff'}}
  />
  <path d="M27.054,25.1a1.221,1.221,0,0,1-.487-.1,1.261,1.261,0,0,1-.4-.267,1.3,1.3,0,0,1-.268-.4,1.231,1.231,0,0,1-.1-.478,1.181,1.181,0,0,1,.1-.48,1.246,1.246,0,0,1,.271-.393,1.264,1.264,0,0,1,.4-.268,1.3,1.3,0,0,1,.49-.1,1.227,1.227,0,0,1,1.132.761,1.222,1.222,0,0,1,.094.477,1.24,1.24,0,0,1-.356.874,1.225,1.225,0,0,1-.879.368m.009-2.176a.981.981,0,0,0-.371.073.94.94,0,0,0-.3.2.866.866,0,0,0-.2.3.854.854,0,0,0-.076.359.916.916,0,0,0,.277.657.934.934,0,0,0,1.53-.3.972.972,0,0,0,.07-.362.931.931,0,0,0-.265-.657.9.9,0,0,0-.657-.277"
    style={{fill: '#fff'}}
  />
  <path d="M28.685,25.045a.153.153,0,0,1-.152-.153V22.806a.155.155,0,0,1,.152-.153.153.153,0,0,1,.152.153v2.086a.151.151,0,0,1-.152.153"
    style={{fill: '#fff'}}
  />
  <path d="M29.248,25.045a.153.153,0,0,1-.152-.153V22.806a.152.152,0,0,1,.152-.153h.9a.715.715,0,0,1,.179.025.74.74,0,0,1,.341.188.66.66,0,0,1,.158.244.915.915,0,0,1,.061.353.7.7,0,0,1-.35.639.88.88,0,0,1-.182.076,1.588,1.588,0,0,1,.344.422c.079.135.164.135.176.135a.152.152,0,0,1,.17.127.153.153,0,0,1-.127.174.426.426,0,0,1-.186-.022.559.559,0,0,1-.3-.261,1.22,1.22,0,0,0-.389-.423.62.62,0,0,0-.192-.091.152.152,0,0,1,.03-.3,1.03,1.03,0,0,0,.5-.1.387.387,0,0,0,.2-.374.591.591,0,0,0-.067-.3.384.384,0,0,0-.14-.14.513.513,0,0,0-.228-.064H29.4v1.934a.154.154,0,0,1-.153.153"
    style={{fill: '#fff'}}
  />
  <path d="M33.055,24.89a.151.151,0,0,1-.152.152H31.448a.153.153,0,0,1-.152-.152V22.805a.153.153,0,0,1,.152-.152h1.424a.153.153,0,0,1,0,.3H31.6v.733h1.125a.153.153,0,0,1,.152.152.151.151,0,0,1-.152.152H31.6v.742h1.3a.152.152,0,0,1,.152.153"
    style={{fill: '#fff'}}
  />
  <path d="M21.215,24.382h-.508a.1.1,0,0,1-.1-.1V22.859a.1.1,0,0,1,.031-.073.108.108,0,0,1,.073-.031h.512a.839.839,0,0,1,.592.24.827.827,0,0,1,.18.259.79.79,0,0,1,.066.317.746.746,0,0,1-.068.314.8.8,0,0,1-.182.259.867.867,0,0,1-.267.174.879.879,0,0,1-.325.064m-.405-.207h.409a.689.689,0,0,0,.243-.05A.623.623,0,0,0,21.8,23.8a.553.553,0,0,0,.05-.229.572.572,0,0,0-.05-.234.6.6,0,0,0-.135-.193.63.63,0,0,0-.447-.182H20.81Z"
    style={{fill: '#fff'}}
  />
  <path d="M23.419,24.276a.1.1,0,0,1-.1.1h-.989a.1.1,0,0,1-.1-.1V22.859a.1.1,0,0,1,.1-.1H23.3a.1.1,0,0,1,0,.207H22.43v.5H23.2a.1.1,0,1,1,0,.207H22.43v.5h.886a.1.1,0,0,1,.1.1"
    style={{fill: '#fff'}}
  />
  <path d="M22.7,6.606a3.675,3.675,0,0,0,1.022,1.922c.108.14.213.284.32.424.051.066.565-.123.31.1-.088.075-.383.267-.5.074-.2-.329-.571-.515-.823-.814-.179.382-.133.5.1.765a1.49,1.49,0,0,1-.152,2.148,1.391,1.391,0,0,1-1.821.049,1.516,1.516,0,0,1-.3-1.957,1.419,1.419,0,0,1,1.508-.624c.2.033.227-.034.245-.2.049-.454.054-.455-.379-.312-.327.107-.655.211-.98.326a.419.419,0,0,1-.315.007.515.515,0,0,1-.262-.189c-.209-.3-.4-.258-.889-.71a.486.486,0,0,1-.176-.315.418.418,0,0,1,.034-.2c.087-.215.216-.213.425-.222s.187-.01.438-.015a3.831,3.831,0,0,0,.658-.125,1.52,1.52,0,0,0,.43-.282,1.822,1.822,0,0,1,.527-.323,1,1,0,0,1,.629.108c.2.079.34.157.544.232a.254.254,0,0,0,.314-.138,1.492,1.492,0,0,1,2.066-.627,1.364,1.364,0,0,1,.457,1.979,1.466,1.466,0,0,1-1.373.649A1.383,1.383,0,0,1,23.5,7.27a.814.814,0,0,0-.8-.664m1.459,1.178a1.164,1.164,0,0,0,1.792-.362,1.023,1.023,0,0,0-.4-1.455,1.167,1.167,0,0,0-1.6.434c-.072.135-.067.2.1.218.291.026.58.065.87.094.117.012.239.03.229.174-.012.169-.15.182-.274.172-.313-.023-.489-.055-.869-.1-.186-.022-.207.032-.186.185a.98.98,0,0,0,.345.641m-2.764,3.292a1.06,1.06,0,0,0,1.373-.057,1.193,1.193,0,0,0,.212-1.584c-.152-.209-.2-.206-.3.042a4.236,4.236,0,0,1-.429.732c-.083.118-.193.219-.322.121-.112-.084-.034-.2.038-.3a4.384,4.384,0,0,0,.441-.754c.081-.181.027-.219-.147-.264a1.079,1.079,0,0,0-1.241.683,1.3,1.3,0,0,0,.38,1.379M20.65,7.283c.185.145.409.415.678.384.376-.044.75-.118,1.126-.177.092-.014.153-.036.126-.154a2.5,2.5,0,0,0-.353-.835c-.07-.105-.143-.1-.225.006a1.91,1.91,0,0,1-1.352.776"
    style={{fill: '#fff'}}
  />
  <path d="M22.808,5.375c.1-.111.2-.225.3-.334.117-.125.229-.119.322,0,.121.158.227.328.353.482.071.088.091.207.036.253-.212.18-.257.312-.462.509a.225.225,0,0,1-.3.029c-.171-.086-.344-.171-.513-.262-.153-.083-.184-.2-.042-.346.1-.109.2-.222.3-.334h0"
    style={{fill: '#fff'}}
  />
  <path d="M19.608,7.062c-.147.136-.4.052-.565-.071a.468.468,0,0,1-.182-.632c.129-.181.466-.15.641-.081a.489.489,0,0,1,.106.784"
    style={{fill: '#fff'}}
  />
  </LogoRoot>
);

export default LogoWhite;
