import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
// import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import Login from '../pages/authentication/Login';
import {getVelos} from './veloRequests.js';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        maxHeight: '90vh',
        overflow: 'hidden',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[16],
        // padding: theme.spacing(2, 4, 3),
        zIndex: 50
    },
}));

const AuthPopup = (props) => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [interval, setinterval] = React.useState(false);
    const [message, setMessage] = React.useState(<Alert sx={{mb: 1}} variant="outlined" severity="error">Vous avez été déconnecté</Alert>);

    const handleOpen = React.useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = React.useCallback(() => {
        setMessage(<Alert sx={{mb: 1}} variant="outlined" severity="success">Connexion réussie</Alert>);
        setTimeout(() => {
            setOpen(false)
        }, 1000);
    }, []);

    const check = React.useCallback(async () => {
        let {status, message} = await getVelos();
        if(status == false) handleOpen();
    }, []);

    //Interval toutes les 30 minutes
    React.useEffect(() => {
        setinterval(setInterval(check, 1800000));
    }, []);

    return <>
        <Modal
            className={classes.modal}
            open={open}
            // onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <div className={classes.paper}>
                <Login close={handleClose}>{message}</Login>
            </div>
        </Modal>
    </>;
};

export default AuthPopup;
