import AjaxRequest from './ajaxRequest';
import { THEMES } from '../constants.js';
import wait from './wait';

export default Request = async (url, data = '', Await = 0, method = 'POST') => {

    url = THEMES.API_URL+'?'+url;
    let status = false;
    let message = null;
    let items = null;

    if(Await && Await > 0)
        await wait(Await);

    if(typeof data == 'object' && data.constructor.name !== 'FormData') {
        let d = Object.assign({}, data); //Cloner l'objet
        data = new Array(`mode=${THEMES.MODE}`);
        for(let i in d) {

            if(d[i] !== null) {
                if(typeof d[i] == 'string') {
                    d[i] = d[i].replace('&gt;', '>').replace('&lt;', '<');
                }
                data.push(`${i}=${d[i]}`);
            }
        }
        data = data.join('&');
    }

    // TODO: Gestion des droits
    await new AjaxRequest(method, url, data, false, true, {
        end: (instance) => {
            if(instance.status == '200') {
                status = true;
                message = instance.HTML;
                if(instance.JSON)
                    items = instance.JSON;
            }
            else { //En cas d'erreur
                status = false;
                message = instance.HTML;
            }
        }
    });

    return {status, message, items};
};
