import { react, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Hidden, IconButton, Toolbar, Typography, Card, CardHeader, Divider, ThemeProvider } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './AccountPopover';
import ContactsPopover from './ContactsPopover';
import ContentSearch from './ContentSearch';
import LanguagePopover from './LanguagePopover';
import LogoWhite from '../LogoWhite';
import NotificationsPopover from './NotificationsPopover';
import WidgetPreviewer from '../WidgetPreviewer';
// PIXIM
import { createTheme } from '../../theme';
import { THEMES } from '../../constants';
import useSettings from '../../hooks/useSettings';
import MoonIcon from '../../icons/Moon';
import SunIcon from '../../icons/Sun';
import AuthPopup from '../../utils/AuthPopup';

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 50%, ${theme.palette.primary.light} 70%, ${theme.palette.success.main}, ${theme.palette.secondary.main})`,
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));

const getValues = (settings) => ({
  compact: settings.compact,
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  theme: settings.theme
});

const DashboardNavbar = (props) => {

    const { onSidebarMobileOpen, ...other } = props;
    const { settings, saveSettings } = useSettings();
    const [values, setValues] = useState(getValues(settings));
    const [selectedTheme, setSelectedTheme] = useState(settings.theme);

    const handleSwitch = () => setSelectedTheme((prevSelectedTheme) => {

        let selected = THEMES.LIGHT;
        if(prevSelectedTheme === THEMES.LIGHT) {
            selected = THEMES.DARK;
        }

        setSelectedTheme(selected);

        setValues({
            ...values,
            "theme": selected
        });
    });

    useEffect(() => {
        saveSettings(values);
    }, [values])

  return (<>
    <AuthPopup />
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
          <Hidden lgUp>
            <IconButton
              color="inherit"
              onClick={onSidebarMobileOpen}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </Hidden>
          <RouterLink to="/">
            <LogoWhite
              sx={{
                m: '0 20px',
                width: 90
              }}
            />
          </RouterLink>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <IconButton onClick={handleSwitch}>
            {selectedTheme === 'LIGHT'
                ? <SunIcon sx={{color: (theme) => theme.palette.primary.contrastText}} fontSize="small" />
                : <MoonIcon sx={{color: (theme) => theme.palette.primary.contrastText}} fontSize="small" />
            }
        </IconButton>
        {/*<LanguagePopover />
        <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box>*/}
        <Box sx={{ ml: 1 }}>
          <ContactsPopover />
        </Box>
        {/*<Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box>*/}
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  </>);
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
