import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Key = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M15.713,5.557a4.256,4.256,0,0,0-3.892,2.5H1.786a.358.358,0,0,0-.253.105L.105,9.59a.357.357,0,0,0,0,.505h0l1.428,1.429a.357.357,0,0,0,.253.1H3.214a.357.357,0,0,0,.253-.1l.818-.819.819.819a.358.358,0,0,0,.5,0h0l.819-.819.819.819a.358.358,0,0,0,.5,0h0l.819-.819.819.819a.357.357,0,0,0,.252.1h2.179a4.284,4.284,0,1,0,3.892-6.071ZM17.5,10.914A1.072,1.072,0,1,1,18.57,9.843,1.071,1.071,0,0,1,17.5,10.914Z" />
  </svg>, 'Key'
);

export default Key;
