const RESSOURCES_DIR = process.env.NODE_ENV === 'development' ? 'http://localhost:7000/avae/public/static' : '/static';
const IMAGES_DIR = RESSOURCES_DIR+'/images';
const SIGNATURE_DIR = RESSOURCES_DIR+'/images';
const DOCUMENTS_DIR = RESSOURCES_DIR+'/documents';
const CLIENTS_DIR = IMAGES_DIR+'/clients';
const CONTRAT_DIR = IMAGES_DIR+'/contrats';
const MAINTENANCE_DIR = IMAGES_DIR+'/maintenances';
const VELO_DIR = '/static/images/velos/';

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
  AVATAR_DIR: '/static/images/avatars/',
  VELO_DIR,
  VELO_DIR_PHOTO: VELO_DIR+'photo/',
  VELO_DIR_FACTURE: VELO_DIR+'facture/',
  VELO_DIR_DOC: VELO_DIR+'doc/',
  IMAGES_DIR: IMAGES_DIR,
  RESSOURCES_DIR: RESSOURCES_DIR+'/',
  DOCUMENTS_DIR: DOCUMENTS_DIR+'/',
  SIGNATURE_CONTRATS_DIR: SIGNATURE_DIR+'/contrats/signatures/',
  SIGNATURE_ETATS_DES_LIEUX_DIR: SIGNATURE_DIR+'/etatsdeslieux/signatures/',
  CLIENTS_DIR: CLIENTS_DIR,
  CNI_DIR: CLIENTS_DIR+'/cni',
  RIB_DIR: CLIENTS_DIR+'/rib',
  JUSTIFICATIF_DOMICILE_DIR: CLIENTS_DIR+'/justificatif_domicile',
  PJ_CONTRAT_DIR: CONTRAT_DIR+'/pieces_jointes',
  PJ_ETATSDESLIEUX_DIR: IMAGES_DIR+'/etatsdeslieux/pieces_jointes',
  BORDEREAUX_DIR: MAINTENANCE_DIR+'/bordereaux',
  FACTURE_DIR: MAINTENANCE_DIR+'/factures_clients',
  LIMIT: 50,
  MODE: process.env.NODE_ENV,
  API_URL: process.env.NODE_ENV === 'development' ? 'http://localhost:7000/avae/public/api/index.php' : '/api/index.php'
};
