import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Klaxon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M18.417,6.5,16.832,7.688a2.505,2.505,0,0,1-1.308.493l-7.212.555c-.1.008-.187-.051-.187-.13h0a.481.481,0,0,0-.481-.481h0a1.263,1.263,0,0,1-.981-.481,3.75,3.75,0,1,0,.011,4.7,1.24,1.24,0,0,1,.968-.468h0a.482.482,0,0,0,.481-.481h0c0-.08.084-.138.187-.13l7.212.555a2.505,2.505,0,0,1,1.308.493L18.417,13.5a1.243,1.243,0,0,0,.75.25h.208A.625.625,0,0,0,20,13.125V6.875a.625.625,0,0,0-.625-.625h-.208A1.243,1.243,0,0,0,18.417,6.5Z" />
    
  </svg>, 'Klaxon'
);

export default Klaxon;
