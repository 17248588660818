import AjaxRequest from './ajaxRequest';
import { THEMES } from '../constants.js';

export const CheckUser = async (email, password) => {

    let data = `login=${email}&password=${password}`;
    let url = THEMES.API_URL+'?view=login&action=authentification';
    let status = true;
    let message = null;
    let user = null;

    await new AjaxRequest('POST', url, data, false, true, {
        end: (instance) => {
            if(instance.status == '200') {
                user = instance.JSON;
            }
            else { //En cas d'erreur
                status = false;
                message = instance.HTML;
            }
        }
    });

    return {status, message, user};
};

export const CheckUserById = async (userId) => {

    let data = `user_id=${userId}`;
    let url = THEMES.API_URL+'?view=login&action=userExists';
    let userExists = {
        status: 500,
        message: null
    };

    await new AjaxRequest('POST', url, data, false, true, {
        end: (instance) => {
            userExists.status = instance.status;
            if(instance.status == '200') {
                userExists.message = instance.JSON;
            }
            else { //En cas d'erreur
                userExists.message = instance.HTML;
            }
        }
    });

    return userExists;
};
