import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { List, ListSubheader } from '@material-ui/core';
import NavItem from './NavItem';
import useSettings from '../hooks/useSettings';
import { useTheme } from '@material-ui/core/styles';

const renderNavItems = ({ depth = 0, items, pathname }) => (
  <List
    xs={{
        fontWeight: 600
    }}
    disablePadding>
    {items.reduce(
      // eslint-disable-next-line no-use-before-define
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        pathname,
        depth
      }), []
    )}
  </List>
);

const reduceChildRoutes = ({ acc, pathname, item, depth }) => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path ? !!matchPath({
    path: item.path,
    end: true
  }, pathname) : false;

  if (item.children) {
    const partialMatch = item.path ? !!matchPath({
      path: item.path,
      end: false
    }, pathname) : false;

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
      />
    );
  }

  return acc;
};

const NavSection = (props) => {
  const { items, pathname, title, ...other } = props;
  const settings = useSettings();
  const theme = useTheme();

  return (
    <List
      subheader={(title ?
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
                // borderRadius: theme.shape.borderRadius,
                // background: theme.palette.secondary.main,
                background: theme.palette.grey[(theme.palette.mode === 'dark' ? 900 : 100)],
                color: theme.palette.text.primary,
                lineHeight: 2.5,
                fontSize: '1rem',
                fontWeight: 600,
                // textTransform: 'uppercase',
                textAlign: 'center',
                boxShadow: theme.shadows[2],
                marginLeft: '-16px',
                marginRight: '-16px',
                borderTop: '1px solid rgba(0,0,0,0.05)'
          }}
        >
          {title}
        </ListSubheader> : null
      )}
      {...other}
    >
      {renderNavItems({
        items,
        pathname
      })}
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string
};

export default NavSection;
