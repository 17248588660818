import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Maintenance = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M18.905,13.62a3.724,3.724,0,0,0-3.23-1.048L9.048,5.945A4.613,4.613,0,0,0,3.487.144a.419.419,0,0,0-.313.5.414.414,0,0,0,.116.2L5.5,3.067l-.537,1.9L3.08,5.513.853,3.29a.42.42,0,0,0-.593,0,.41.41,0,0,0-.116.21,4.613,4.613,0,0,0,5.8,5.553l6.627,6.627a3.737,3.737,0,1,0,6.334-2.055Zm-1.678,3.6a.959.959,0,1,0-.009,0Z" />
  </svg>, 'Maintenance'
);

export default Maintenance;
