
	function AjaxRequest(methode, url, params, responseContenaire, returnCodeResponse, callback, element) {

		//Set attributs
		methode = methode || 'get';
		this.methode = methode.toUpperCase();
		this.url = url || '.';
		this.params = '';
		this.formData = ('FormData' in window) ? (params.constructor === FormData) : false;
		var params = (params && !this.formData) ? params.split('&') : params;
		var params_length = params.length;
		this.callback = callback || {};
		this.element = element || null;

		for(let i = 0; i < params_length && !this.formData; i++) {

			var p = (params_length - 1 == i) ? '' : '&';
			var value = encodeURIComponent(params[i]);
			value = value.replace('%3D', '=');

			this.params += value + p;
		}

		if(this.formData)
			this.params = params;

		if(responseContenaire) {

			if(typeof responseContenaire == "object")
				this.responseContenaire = responseContenaire;
			else
				this.responseContenaire = document.getElementById(responseContenaire);
		}

		this.start = this.Time();
		this.finish = false;
		this.OnProgress = false;

		//Set XHR Object
		try {
			this.SetParams(returnCodeResponse);
		} catch(e) {
			console.log(e);
			return false;
		}

		if(this.methode == 'GET') {

			if(this.params)
				this.url += (this.url.indexOf('?') > -1) ? '&'+this.params : '?'+this.params;
			this.params = null;
		}

		this.Send();
	}

	AjaxRequest.prototype = {

		SetParams: function(returnCodeResponse) {

			this.xhr = this.getXHRInstance();
			this.xhr.responseContenaire = this.responseContenaire;
			this.xhr.start = this.start;
			//Autoriser les cookies d'une réponse en Ajax d'un domaine différent
			this.xhr.withCredentials = true;
			this.xhr.Time = this.Time;
			this.xhr.currentObject = this;
			this.xhr.returnCodeResponse = returnCodeResponse ? false : true; //Requête Synchrone ou Asynchrone
		},
		Send: function() {

			// if(this.OnProgress) this.Abort();

			this.xhr.open(this.methode, this.url, this.xhr.returnCodeResponse);
			if(this.methode == 'POST' && !this.formData)
				this.xhr.setRequestHeader("Content-type","application/x-www-form-urlencoded");
			else if(this.formData)
				this.xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

			this.xhr.onabort = this.OnAbort ;
			this.xhr.onreadystatechange = this.ReadyStateFunction ;

			this.xhr.onloadstart = function() { // Déclenché lorsque la requête commence

				if(typeof this.currentObject.callback.start == "function")
					this.currentObject.callback.start(this.currentObject);
			};
			this.xhr.onload = function() { 	//Déclenché après readyStateChange == 4

				// if(typeof this.currentObject.callback.end == "function")
					// this.currentObject.callback.end.call();
			};
			this.xhr.onloadend = function() {  };
			this.xhr.onprogress = function(oEvent) {
				// console.log('onprogress lengthComputable : ' + oEvent.lengthComputable);
				// console.log('onprogress loaded : ' + oEvent.loaded );
				// console.log('onprogress total : ' + oEvent.total );
			};

			this.OnProgress = true;
			this.xhr.send(this.params);
		},
		ReadyStateFunction: function() {

			if(this.readyState == 4 && this.status != 0) {

				this.currentObject.finish = true;
				this.currentObject.OnProgress = false;

				var contentType = (this.getResponseHeader('Content-Type').split(';'))[0];
				var HTML = this.responseText;
				var XML = this.responseXML || new Object();
				var isJson = (contentType == 'application/json' || contentType == 'text/javascript') ? true : false;
				var end = this.Time();
				var duration = Math.round((end - this.start) * 100) / 100+'s' ;
				this.currentObject.status = this.status;

				if(isJson) {
					try {
						this.currentObject.JSON = JSON.parse(HTML, (key, value) => {
						    // return typeof value == 'string' ? value.replace(/\+/, '\\') : value;
						    return value;
						});
					}
					catch(e) {
						this.currentObject.JSON = HTML;
					}
				}
				else if(XML.documentElement != null) { //Si XML

					this.currentObject.XML = XML;
				}
				else { //Si HTML ou text

					if(this.currentObject.element) {
						var domVirtual = document.createElement('div');
						domVirtual.innerHTML = HTML;
						var node = domVirtual.querySelector(this.currentObject.element);
						if(node)
							HTML = node.innerHTML;
					}

					if(this.responseContenaire) {
						this.responseContenaire.innerHTML += HTML;
					}
					this.currentObject.HTML = HTML;

				}
				//Callback end
				if(typeof this.currentObject.callback.end == "function")
					this.currentObject.callback.end(this.currentObject);
			}
		},
		getXHRInstance: function() {

			if('XMLHttpRequest' in window)
				return new XMLHttpRequest();
			else if('XDomainRequest' in window) // For IE
				return new window.XDomainRequest();
			else if('ActiveXObject' in window)  // For old IE
				return new ActiveXObject("Microsoft.XMLHTTP");
			else
				throw new Error('Impossible d\'instancier un objet XHR')
		},
		getHTML: function() {

			return this.HTML;
		},
		getXML: function() {

			return this.XML;
		},
		getJSON: function() {

			return this.JSON || null;
		},
		getStatus: function() {

			return this.status || null;
		},
		Time: function() {

			var timestamp_unix = new Date().getTime() / 1000;
			return timestamp_unix;
		},
		Abort: function() {

			this.xhr.abort();
		},
		OnAbort: function() {

			this.abortStatus = true;
			this.responseContenaire.innerHTML += '<div class="item center"><strong>Requête annulée</strong></div>';
		}
	};

    export default AjaxRequest;
