import Request from './Request';
import Exporter from './Exporter';

export const getVelos = async (params) => {
    return await Request('view=velos', params, 10, 'GET');
};

export const saveVelo = async (params) => {
    return await Request('view=velos&action=save', params);
};

export const modifyVelo = async (params) => {
    return await Request('view=velos&action=modify', params);
};

export const deleteVelos = async (deleteVeloIds) => {
    return await Request('view=velos&action=delete', 'velos='+deleteVeloIds.join(','));
};

export const exportVelos = async (params) => {
    Exporter('view=velos&action=exportVelos', params);
};
