import { mock } from '../lib/axios';
import createResourceId from '../utils/createResourceId';
import { sign, decode, JWT_SECRET, JWT_EXPIRES_IN } from '../utils/jwt';
import {CheckUser, CheckUserById} from '../utils/userRequests';
import { THEMES } from '../constants.js';
import wait from '../utils/wait';

mock
  .onPost('/api/authentication/login')
  .reply(async (config) => {
    // await wait(1000);
    try {
      const { email, password } = JSON.parse(config.data);
      const {status, message, user} = await CheckUser(email, password);

      if(!status || !user) {
        return [400, { message: message }];
      }

      // Create the access token
      const accessToken = sign({ userId: user.id }, JWT_SECRET, { expiresIn: JWT_EXPIRES_IN });
      return [
        200, {
          accessToken,
          user: {
            id: user.id,
            avatar: THEMES.AVATAR_DIR + user.image,
            email: user.email,
            name: `${user.prenom} ${user.nom}`,
            nom: user.nom || '',
            prenom: user.prenom || '',
            telephone: user.telephone || '',
            plan: user.groupe,
            token: user.token
          }
        }
      ];

    } catch (err) {
      console.error('[Mock]: ', err);
      return [500, { message: 'Erreur serveur...' }];
    }

});

mock
  .onPost('/api/authentication/register')
  .reply(async (config) => {
    await wait(1000);

    try {
      const { email, name, password } = JSON.parse(config.data);

      // Check if a user already exists
      let user = users.find((_user) => _user.email === email);

      if (user) {
        return [400, { message: 'User already exists' }];
      }

      user = {
        id: createResourceId(),
        avatar: null,
        email,
        name,
        password,
        plan: 'Standard'
      };

      users.push(user);

      const accessToken = sign({ userId: user.id }, JWT_SECRET, { expiresIn: JWT_EXPIRES_IN });

      return [
        200, {
          accessToken,
          user: {
            id: user.id,
            avatar: user.avatar,
            email: user.email,
            name: user.name,
            plan: user.plan
          }
        }
      ];
    } catch (err) {
      console.error('[Mock]: ', err);
      return [500, { message: err }];
    }
  });

mock
  .onGet('/api/identity/me')
  .reply(async (config) => {
    try {
      // Ensure authorization is provided
      const { Authorization } = config.headers;

      if (!Authorization) {
        return [401, { message: 'Authorization required' }];
      }

      // Extract access token
      const accessToken = Authorization.split(' ')[1];

      // Decode access token
      const { userId } = decode(accessToken);

      // Find the user
      const {status, message} = await CheckUserById(userId);

      if (status != 200) {
        return [401, { message: message }];
      }

      return [
        200, {
          user: {
            id: message.id,
            avatar: THEMES.AVATAR_DIR + message.image,
            email: message.email,
            name: `${message.prenom} ${message.nom}`,
            nom: message.nom || '',
            prenom: message.prenom || '',
            telephone: message.telephone || '',
            plan: message.groupe,
            token: message.token
          }
        }
      ];
    } catch (err) {
      console.error('[Mock]: ', err);
      return [500, { message: 'Internal server error' }];
    }
  });
