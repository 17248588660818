import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Cycle = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
      <path d="M4.017,16.08a4.017,4.017,0,1,1,4.017-4.017A4.022,4.022,0,0,1,4.017,16.08Zm0-6.709a2.692,2.692,0,1,0,2.692,2.692A2.7,2.7,0,0,0,4.017,9.371Z" />
      <path d="M15.983,16.08A4.017,4.017,0,1,1,20,12.063,4.022,4.022,0,0,1,15.983,16.08Zm0-6.709a2.692,2.692,0,1,0,2.692,2.692A2.7,2.7,0,0,0,15.983,9.371Z" />
      <polygon points="15.364 8.302 12.582 1.325 11.855 1.325 11.855 0 13.478 0 16.597 7.809 15.364 8.302"/>
      <path d="M9.777,12.743h-6.7L5.342,6.361H14.4ZM4.956,11.418H9.1l2.706-3.732H6.281Z" />
      <rect x="4.28" y="1.193" width="2.739" height="1.325"/>
  </svg>, 'Cycle'
);

export default Cycle;
