import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import BlogLayout from './components/blog/BlogLayout';
import BrowseLayout from './components/BrowseLayout';
import DashboardLayout from './components/dashboard/DashboardLayout';
import DocsLayout from './components/docs/DocsLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import Fade from './utils/fade.jsx';
import AuthPopup from './utils/AuthPopup.jsx';


const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Browse pages

const Browse = Loadable(lazy(() => import('./pages/browse/Browse')));
const BrowseButtons = Loadable(lazy(() => import('./pages/browse/BrowseButtons')));
const BrowseCharts = Loadable(lazy(() => import('./pages/browse/BrowseCharts')));
const BrowseColors = Loadable(lazy(() => import('./pages/browse/BrowseColors')));
const BrowseDetailLists = Loadable(lazy(() => import('./pages/browse/BrowseDetailLists')));
const BrowseForms = Loadable(lazy(() => import('./pages/browse/BrowseForms')));
const BrowseGridLists = Loadable(lazy(() => import('./pages/browse/BrowseGridLists')));
const BrowseGroupedLists = Loadable(lazy(() => import('./pages/browse/BrowseGroupedLists')));
const BrowseInputs = Loadable(lazy(() => import('./pages/browse/BrowseInputs')));
const BrowseModals = Loadable(lazy(() => import('./pages/browse/BrowseModals')));
const BrowseQuickStats = Loadable(lazy(() => import('./pages/browse/BrowseQuickStats')));
const BrowseTables = Loadable(lazy(() => import('./pages/browse/BrowseTables')));
const BrowseTypography = Loadable(lazy(() => import('./pages/browse/BrowseTypography')));

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Blog pages

const BlogPostCreate = Loadable(lazy(() => import('./pages/blog/BlogPostCreate')));
const BlogPostDetails = Loadable(lazy(() => import('./pages/blog/BlogPostDetails')));
const BlogPostList = Loadable(lazy(() => import('./pages/blog/BlogPostList')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')));
const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CustomerDetails')));
const CustomerEdit = Loadable(lazy(() => import('./pages/dashboard/CustomerEdit')));
const CustomerList = Loadable(lazy(() => import('./pages/dashboard/CustomerList')));
const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
const InvoiceDetails = Loadable(lazy(() => import('./pages/dashboard/InvoiceDetails')));
const InvoiceList = Loadable(lazy(() => import('./pages/dashboard/InvoiceList')));
const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
const OrderDetails = Loadable(lazy(() => import('./pages/dashboard/OrderDetails')));
const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
//PIXIM
const MarquesListe = Loadable(lazy(() => import('./pages/dashboard/MarquesListe')));
const NouvelleMarque = Loadable(lazy(() => import('./pages/dashboard/NouvelleMarque')));
const MaintenanceList = Loadable(lazy(() => import('./pages/dashboard/MaintenanceList')));
const NouvelleMaintenance = Loadable(lazy(() => import('./pages/dashboard/NouvelleMaintenance')));
const SinistreList = Loadable(lazy(() => import('./pages/dashboard/SinistreList')));
const NouveauSinistre = Loadable(lazy(() => import('./pages/dashboard/NouveauSinistre')));
const AbonnementsListe = Loadable(lazy(() => import('./pages/dashboard/AbonnementsListe')));
const NouvelAbonnement = Loadable(lazy(() => import('./pages/dashboard/NouvelAbonnement')));
const FournisseursListe = Loadable(lazy(() => import('./pages/dashboard/FournisseursListe')));
const NouveauFournisseur = Loadable(lazy(() => import('./pages/dashboard/NouveauFournisseur')));
const ModelesListe = Loadable(lazy(() => import('./pages/dashboard/ModelesListe')));
const NouveauModele = Loadable(lazy(() => import('./pages/dashboard/NouveauModele')));
const NouveauVelo = Loadable(lazy(() => import('./pages/dashboard/NouveauVelo')));
const VeloList = Loadable(lazy(() => import('./pages/dashboard/VeloList')));
const NouvelEtatDesLieux = Loadable(lazy(() => import('./pages/dashboard/NouvelEtatDesLieux')));
const EtatDesLieuxList = Loadable(lazy(() => import('./pages/dashboard/EtatDesLieuxList')));
const NouvelAccessoire = Loadable(lazy(() => import('./pages/dashboard/NouvelAccessoire')));
const AccessoireList = Loadable(lazy(() => import('./pages/dashboard/AccessoireList')));
const NouvellePiece = Loadable(lazy(() => import('./pages/dashboard/NouvellePiece')));
const PieceList = Loadable(lazy(() => import('./pages/dashboard/PieceList')));
const NouveauClient = Loadable(lazy(() => import('./pages/dashboard/NouveauClient')));
const ClientList = Loadable(lazy(() => import('./pages/dashboard/ClientList')));
const NouvelleCommande = Loadable(lazy(() => import('./pages/dashboard/NouvelleCommande')));
const CommandeList = Loadable(lazy(() => import('./pages/dashboard/CommandeList')));
const Parametrages = Loadable(lazy(() => import('./pages/dashboard/Parametrages')));

//Contrats
const ContratEdit = Loadable(lazy(() => import('./pages/dashboard/ContratEdit')));
const ContratList = Loadable(lazy(() => import('./pages/dashboard/ContratList')));
//PIXIM
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const ProductCreate = Loadable(lazy(() => import('./pages/dashboard/ProductCreate')));
const ProductList = Loadable(lazy(() => import('./pages/dashboard/ProductList')));

// Docs pages

const Docs = Loadable(lazy(() => import('./pages/Docs')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Projects pages

const ProjectBrowse = Loadable(lazy(() => import('./pages/dashboard/ProjectBrowse')));
const ProjectCreate = Loadable(lazy(() => import('./pages/dashboard/ProjectCreate')));
const ProjectDetails = Loadable(lazy(() => import('./pages/dashboard/ProjectDetails')));

// Social pages

const SocialFeed = Loadable(lazy(() => import('./pages/dashboard/SocialFeed')));
const SocialProfile = Loadable(lazy(() => import('./pages/dashboard/SocialProfile')));

// Other pages

const Checkout = Loadable(lazy(() => import('./pages/Checkout')));
const Contact = Loadable(lazy(() => import('./pages/Contact')));
const Home = Loadable(lazy(() => import('./pages/Home')));
const Pricing = Loadable(lazy(() => import('./pages/Pricing')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: 'blog',
    element: <BlogLayout />,
    children: [
      {
        path: '/',
        element: <BlogPostList />
      },
      {
        path: 'new',
        element: <BlogPostCreate />
      },
      {
        path: ':postId',
        element: <BlogPostDetails />
      }
    ]
  },
  {
    path: 'contact',
    element: <Contact />
  },
  { //ROOT
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'analytics',
        element: <Analytics />
      },
      {
        path: 'parametrages',
        element: <Parametrages />
      },
      {
        path: 'calendar',
        element: <Calendar />
      },
      {
        path: 'chat',
        children: [
          {
            path: '/',
            element: <Chat />
          },
          {
            path: 'new',
            element: <Chat />
          },
          {
            path: ':threadKey',
            element: <Chat />
          }
        ]
      },
      {
        path: 'customers',
        children: [
          {
            path: '/',
            element: <CustomerList />
          },
          {
            path: ':customerId',
            element: <CustomerDetails />
          },
          {
            path: ':customerId/edit',
            element: <CustomerEdit />
          }
        ]
      },
      {
        path: 'invoices',
        children: [
          {
            path: '/',
            element: <InvoiceList />
          },
          {
            path: ':invoiceId',
            element: <InvoiceDetails />
          }
        ]
      },
      {
        path: 'kanban',
        element: <Kanban />
      },
      {
        path: 'mail',
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="/mail/all"
                replace
              />
            )
          },
          {
            path: 'label/:customLabel',
            element: <Mail />
          },
          {
            path: 'label/:customLabel/:emailId',
            element: <Mail />
          },
          {
            path: ':systemLabel',
            element: <Mail />
          },
          {
            path: ':systemLabel/:emailId',
            element: <Mail />
          }
        ]
      },
      {
        path: 'orders',
        children: [
          {
            path: '/',
            element: <OrderList />
          },
          {
            path: ':orderId',
            element: <OrderDetails />
          }
        ]
      },
      {
        path: '/marques',
        children: [
          {
            path: '/',
            element: <MarquesListe />
          },
          {
            path: ':marqueId',
            element: <NouvelleMarque />
            // element: <OrderDetails />
          }
        ]
      },
      {
        path: '/modeles',
        children: [
          {
            path: '/',
            element: <ModelesListe />
          },
          {
            path: ':modeleId',
            element: <NouveauModele />
          }
        ]
      },
      {
        path: 'finance',
        element: <Finance />
      },
      {
        path: 'products',
        children: [
          {
            path: '/',
            element: <ProductList />
          },
          {
            path: 'new',
            element: <ProductCreate />
          }
        ]
      },
      {
        path: 'velos',
        children: [
            {
                path: '/',
                element: <VeloList />
            },
            {
                path: ':veloId',
                element: <NouveauVelo />
            },
            {
                path: '/statut/:veloStatut',
                element: <VeloList />
            },
            {
                path: '/revision/:veloRevision',
                element: <VeloList />
            }
        ]
      },
      {
        path: 'etatsdeslieux',
        children: [
          {
            path: '/',
            element: <EtatDesLieuxList />
          },
          {
            path: ':etatdeslieuxId',
            element: <NouvelEtatDesLieux />
        }
        ]
      },
      {
        path: 'accessoires',
        children: [
          {
            path: '/',
            element: <AccessoireList />
          },
          {
            path: ':accessoireId',
            element: <NouvelAccessoire />
        }
        ]
      },
      {
        path: 'pieces',
        children: [
          {
            path: '/',
            element: <PieceList />
          },
          {
            path: ':pieceId',
            element: <NouvellePiece />
        }
        ]
      },
      {
        path: 'abonnements',
        children: [
            {
                path: '/',
                element: <AbonnementsListe />
            },
            {
                path: ':abonnementId',
                element: <NouvelAbonnement />
            }
        ]
      },
      {
        path: 'sinistres',
        children: [
            {
                path: '/',
                element: <SinistreList />
            },
            {
                path: ':sinistreId',
                element: <NouveauSinistre />
            }
        ]
      },
      {
        path: 'maintenances',
        children: [
            {
                path: '/',
                element: <MaintenanceList />
            },
            {
                path: '/statut/:statutMaintenance',
                element: <MaintenanceList />
            },
            {
                path: '/non-cloturees/:nonCloturees',
                element: <MaintenanceList />
            },
            {
                path: '/location-en-cours/:locationEnCours',
                element: <MaintenanceList />
            },
            {
                path: ':maintenanceId',
                element: <NouvelleMaintenance />
            }
        ]
      },
      {
        path: 'fournisseurs',
        children: [
            {
                path: '/',
                element: <FournisseursListe />
            },
            {
                path: ':fournisseurId',
                element: <NouveauFournisseur />
            }
        ]
      },
      {
        path: 'clients',
        children: [
            {
                path: '/',
                element: <ClientList />
            },
            {
                path: '/liste-attente/:enAttente',
                element: <ClientList />
            },
            {
                path: ':clientId',
                element: <NouveauClient />
            }
        ]
      },
      {
        path: 'contrats',
        children: [
            {
                path: '/',
                element: <ContratList />
            },
            {
                path: '/inscriptions-en-attente/:inscriptionsAttente',
                element: <ContratList />
            },
            {
                path: '/abonnement-echu-sans-retour/:day',
                element: <ContratList />
            }
        ]
      },
      {
        path: 'commandes',
        children: [
            {
                path: '/',
                element: <CommandeList />
            },
            {
                path: ':commandeId',
                element: <NouvelleCommande />
            },
            {
                path: ':commandeId/:contratId',
                element: <NouvelleCommande />
            }
        ]
      },
      {
        path: 'projects',
        children: [
          {
            path: 'browse',
            element: <ProjectBrowse />
          },
          {
            path: 'new',
            element: <ProjectCreate />
          },
          {
            path: ':projectId',
            element: <ProjectDetails />
          }
        ]
      },
    ]
  },
  // {
  //   path: 'docs',
  //   element: <DocsLayout />,
  //   children: [
  //     {
  //       path: '/',
  //       element: (
  //         <Navigate
  //           to="/docs/overview/welcome"
  //           replace
  //         />
  //       )
  //     },
  //     {
  //       path: '*',
  //       element: <Docs />
  //     }
  //   ]
  // }
];

export default routes;
